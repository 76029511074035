import * as React from "react";
import styled from "styled-components";

import Layout from "../components/Layout";

const Title = styled.div`
  ${props => props.theme.typography.h4};
  text-align: center;

  margin-bottom: 2rem;
`;

const BodyText = styled.p`
  ${props => props.theme.typography.body1};
`;

const Heading = styled.h1`
  ${props => props.theme.typography.h5};
`;

const Text = styled.div`
  margin: 0 auto;
  margin-bottom: 4rem;
  max-width: 650px;
`;

const Link = styled.a.attrs({target: "_blank"})``;

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Title>Privacy Policy</Title>
      <Text>
        <BodyText>Last Updated: March 29, 2020</BodyText>
        <BodyText>
          This privacy policy governs your use of the software application
          coachq (“Application”) for mobile devices that was created by Michal
          Dedys. Our privacy policy may change from time to time for any reason,
          we will place a prominent notice on our website or application. If you
          have any questions or concerns about our privacy policies, feel free
          to contact us.
        </BodyText>
        <Heading>
          What information does the Application obtain and how is it used?
        </Heading>
        <BodyText>
          The Application does not collect or transmit any personally
          identifiable information about you, such as your name, address, phone
          number or email address.
        </BodyText>
        <Heading>How do you handle location data?</Heading>
        <BodyText>
          The Application does not use or collect any data related to your
          geographic location.
        </BodyText>
        <Heading>Can users see their personal data?</Heading>
        <BodyText>
          The Application itself does not collect, transmit, or maintain user
          data.
        </BodyText>
        <Heading>Do you share personal information?</Heading>
        <BodyText>
          As no personal information is collected, transmitted, or maintained by
          the Application, we do not share personal information with anyone.
        </BodyText>
        <Heading>Do advertising companies collect data?</Heading>
        <BodyText>
          The Application has no facility for collecting, transmitting, or
          maintaining user data, so no data is shared with advertising
          companies.
        </BodyText>
        <Heading>Do you use vendors or analytics providers?</Heading>
        <BodyText>
          No. The Application has no facility for collecting, transmitting, or
          maintaining user data, so no data is shared with vendors or analytics
          providers.
        </BodyText>
        <Heading>Service Providers</Heading>
        <BodyText>
          This website is hosted using Netlify. See Netlify{" "}
          <Link href="https://www.netlify.com/privacy/">
            Privacy Statement.
          </Link>
        </BodyText>
      </Text>
    </Layout>
  );
};

export default PrivacyPolicy;
